import React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';

class App extends React.Component {

    render() {
        return (
      <Layout>
                <Route path='/' component={props => <Home {...props} />} />
      </Layout>
        )};
}

export default App;