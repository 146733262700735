import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CustomInput, Input, FormFeedback, FormGroup, Label } from "reactstrap";
import Format from "../utils/Format";
import States from "../utils/States";
import Submit from "./Submit";
import apikey from "../utils/api-key";

export class Home extends PureComponent {
	state = {
		data: {},
		id: null,
		showSubmit: false
	}

	_answerQueue = []

	_answerQueueRunning = false;

	queueAnswer = (name, value) => {
		const questionnaireId = this.state.id;
		const data = { questionnaireId: questionnaireId, name: name, value: value };
		var answer = this._answerQueue.find(q => q.key === name);
		if (answer) {
			answer.data = data;
		} else {
			answer = { key: name, data: data };
			this._answerQueue.push(answer);
		}
		this.sendAnswers();
	}

	sendAnswers = async () => {
		if (this._answerQueueRunning) return;
		this._answerQueueRunning = true;
		while (this._answerQueue.length > 0) {
			const answer = this._answerQueue.shift();
			const data = answer.data;
			try {
				const url = `api/Answers`;
				const response = await fetch(url,
					{
						method: "POST",
						body: JSON.stringify(data),
						headers: {
							'Content-Type': "application/json",
							'X-API-Key': apikey
						}
					});
				if (!response.ok) {
					console.log(response);
				}
			} catch (exception) {
				console.log(exception);
			}
		}
		this._answerQueueRunning = false;
	}

	handleRadio = (event) => {
		this.saveAnswer(event.target.name, event.target.value);
	}

	handleSelect = (event) => {
		this.saveAnswer(event.target.id, event.target.value);
	}

	handleText = (event) => {
		this.saveAnswer(event.target.id, event.target.value);
	}

	saveAnswer = (name, value) => {
		var data = {
			...this.state.data,
			[name]: value
		};
		this.setState({ data: data });
		this.queueAnswer(name, value);
	}

	createQuestionnaire = async () => {
		try {
			const url = `api/Questionnaires`;
			const data = {
			};
			const response = await fetch(url,
				{
					method: "POST",
					body: JSON.stringify(data),
					headers: {
						'Content-Type': "application/json",
						'X-API-Key': apikey
					}
				});
			if (!response.ok) {
				return;
			}
			const result = await response.json();
			this.setState({ id: result.id });
		} catch (exception) {
			console.log(exception);
		}
	}

	showSubmit = async () => {
		this.setState({ showSubmit: true });
		const { id } = this.state;
		try {
			const url = `api/Questionnaires/${id}/Submit`;
			const response = await fetch(url,
				{
					headers: {
						'X-API-Key': apikey
					}
				});
			if (!response.ok) {
				return;
			} else {
				const url = `api/Questionnaires/${id}/Historize`;
				const response = await fetch(url,
				{
					headers: {
						'X-API-Key': apikey
					}
				});
				if (!response.ok) {
					return;
				}
			}
		} catch (exception) {
			console.log(exception);
		}
	}

	okSubmit = () => {
		this.reset();
	}

	cancelSubmit = () => {
		this.reset();
	}

	reset = () => {
		const defaultState = {
			data: {},
			id: null,
			showSubmit: false
		};
		this.setState(defaultState);
		this.createQuestionnaire();
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		this.createQuestionnaire();
	}

	render() {
		const { data, showSubmit } = this.state;
		const validationState = {};
		return (
			<div>
			<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">Welcome</h4>
				<CardBody>
					<div className="float-left"><img src="HB.png" width="179" height="75" alt="H-B" /></div>
					<h3 className="clearfix">Startup Diagnostic Tool</h3>
					<p>Husch Blackwell's Startup Diagnostic Tool consists of a few simple questions about your business.
						It is designed to allow your attorney to quickly assess the legal "health" of your emerging company in a few key areas.</p>
					<p>This report is intended to:</p>
					<p>Ensure you and your attorney are focusing on the most important legal issues that your company is currently facing.<br/>
						Serve as a roadmap for addressing any additional legal issues.</p>
					<p>You will receive a copy of this "health assessment" when you meet with your attorney.</p>
					<p>Completion of this form does not create an attorney/client relationship.  If for some reason you do not become a Husch Blackwell client, any and all data you supplied will be discarded without review.</p>
					<p>If you have any questions about this survey, please contact [email]@huschblackwell.com.</p>
				</CardBody>
			</Card>


			<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">Formation</h4>
				<CardBody>
					<FormGroup>
						<Label for="employees">How many employees does your company have?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="employees0" name="employees" label="0" value="0" onChange={
this.handleRadio} checked={data.employees === "0"}/>
							<CustomInput type="radio" id="employees1" name="employees" label="1-20" value="1-20" onChange={
this.handleRadio} checked={data.employees === "1-20"}/>
							<CustomInput type="radio" id="employees21" name="employees" label="21-50" value="21-50" onChange={
this.handleRadio} checked={data.employees === "21-50"}/>
							<CustomInput type="radio" id="employees51" name="employees" label="> 50" value="> 50" onChange={
this.handleRadio} checked={data.employees === "> 50"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="filed">Have you filed your entity with the Secretary of State? </Label>
						<div className="switch-field">
							<CustomInput type="radio" id="filedYes" name="filed" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.filed === "Yes"}/>
							<CustomInput type="radio" id="filedNo" name="filed" label="No" value="No" onChange={
this.handleRadio} checked={data.filed === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					{ data.filed === "Yes" &&
					<React.Fragment>
					<FormGroup>
						<Label for="state">In what state is your entity registered? </Label>
						<Input id="state" type="select" placeholder="State" value={Format.formatTextInput(
							data.state)
} onChange={this.handleText} valid={validationState.state}>
							<option value="" disabled> - Select State -</option>
							{States.map(state => <option key={state}>{state}</option>)}
						</Input>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="registered">Has your company registered with appropriate State and Federal agencies? </Label>
						<div className="switch-field">
							<CustomInput type="radio" id="registeredYes" name="registered" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.registered === "Yes"}/>
							<CustomInput type="radio" id="registeredNo" name="registered" label="No" value="No" onChange={
this.handleRadio} checked={data.registered === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="entityType">What type of entity is your company?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="entityTypeCorporation" name="entityType" label="Corporation" value="Corporation" onChange={
this.handleRadio} checked={data.entityType === "Corporation"}/>
							<CustomInput type="radio" id="entityTypeLLC" name="entityType" label="LLC" value="LLC" onChange={
this.handleRadio} checked={data.entityType === "LLC"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="registeredDate">On what date was your company registered?</Label>
						<Input id="registeredDate" type="date" placeholder="Registered Date" value={Format.formatTextInput(data.registeredDate)
} onChange={this.handleText} valid={validationState.registeredDate}/>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="ein">Has the company registered for an EIN?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="einYes" name="ein" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.ein === "Yes"}/>
							<CustomInput type="radio" id="einNo" name="ein" label="No" value="No" onChange={
this.handleRadio} checked={data.ein === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					{ data.entityType === "Corporation" &&
						<React.Fragment>
							<FormGroup>
								<Label for="sCorp">Has your Corporation made an election to be registered as an S Corp?</Label>
								<div className="switch-field">
									<CustomInput type="radio" id="sCorpYes" name="sCorp" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.sCorp === "Yes"}/>
									<CustomInput type="radio" id="sCorpNo" name="sCorp" label="No" value="No" onChange={
this.handleRadio} checked={data.sCorp === "No"}/>
								</div>
								<FormFeedback/>
							</FormGroup>
							<FormGroup>
								<Label for="bylaws">Do you have Corporate bylaws that have been properly adopted by your Corporation?</Label>
								<div className="switch-field">
									<CustomInput type="radio" id="bylawsYes" name="bylaws" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.bylaws === "Yes"}/>
									<CustomInput type="radio" id="bylawsNo" name="bylaws" label="No" value="No" onChange={
this.handleRadio} checked={data.bylaws === "No"}/>
								</div>
								<FormFeedback/>
							</FormGroup>
							<FormGroup>
								<Label for="shareholders">How many shareholders does your Corporation currently have?</Label>
								<div className="switch-field">
									<CustomInput type="radio" id="shareholders1" name="shareholders" label="1" value="1" onChange={
this.handleRadio} checked={data.shareholders === "1"}/>
									<CustomInput type="radio" id="shareholders2" name="shareholders" label="2-10" value="2-10" onChange={
this.handleRadio} checked={data.shareholders === "2-10"}/>
									<CustomInput type="radio" id="shareholders11" name="shareholders" label="11-100" value="11-100" onChange={
this.handleRadio} checked={data.shareholders === "11-100"}/>
									<CustomInput type="radio" id="shareholders100" name="shareholders" label="100+" value="100+" onChange={
this.handleRadio} checked={data.shareholders === "100+"}/>
								</div>
								<FormFeedback/>
							</FormGroup>
						</React.Fragment>
					}
					{ data.entityType === "LLC" &&
						<React.Fragment>
							<FormGroup>
								<Label for="taxed">How is your LLC taxed?</Label>
								<div className="switch-field">
									<CustomInput type="radio" id="taxedSCorp" name="taxed" label="S Corp" value="S Corp" onChange={
this.handleRadio} checked={data.taxed === "S Corp"}/>
									<CustomInput type="radio" id="taxedPartnership" name="taxed" label="Partnership" value="Partnership" onChange={
this.handleRadio} checked={data.taxed === "Partnership"}/>
								</div>
								<FormFeedback/>
							</FormGroup>
							<FormGroup>
								<Label for="operatingAgreement">Do you have an Operating agreement signed by each member of your LLC?</Label>
								<div className="switch-field">
									<CustomInput type="radio" id="operatingAgreementYes" name="operatingAgreement" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.operatingAgreement === "Yes"}/>
									<CustomInput type="radio" id="operatingAgreementNo" name="operatingAgreement" label="No" value="No" onChange={
this.handleRadio} checked={data.operatingAgreement === "No"}/>
								</div>
								<FormFeedback/>
							</FormGroup>
							<FormGroup>
								<Label for="members">How many members does your LLC have?</Label>
								<div className="switch-field">
									<CustomInput type="radio" id="members1" name="members" label="1" value="1" onChange={
this.handleRadio} checked={data.members === "1"}/>
									<CustomInput type="radio" id="members2" name="members" label="2+" value="2+" onChange={
this.handleRadio} checked={data.members === "2+"}/>
								</div>
								<FormFeedback/>
							</FormGroup>
						</React.Fragment>
					}
					</React.Fragment>
					}
				</CardBody>
			</Card>

			{ data.employees && data.employees !== "0" &&
			<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">Employees</h4>
				<CardBody>
					<FormGroup>
						<Label for="employmentAgreements">Do you have employment agreements or are all employees considered to be "at will"?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="employmentAgreementsEmploymentAgreements" name="employmentAgreements" label="Employment Agreements" value="Employment Agreements" onChange={
this.handleRadio} checked={data.employmentAgreements === "Employment Agreements"}/>
							<CustomInput type="radio" id="employmentAgreementsAtWill" name="employmentAgreements" label="At Will" value="At Will" onChange={
this.handleRadio} checked={data.employmentAgreements === "At Will"}/>
							<CustomInput type="radio" id="employmentAgreementsOther" name="employmentAgreements" label="Other" value="Other" onChange={
this.handleRadio} checked={data.employmentAgreements === "Other"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="confidentialInformation">Do employees have access to confidential information or trade secrets?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="confidentialInformationYes" name="confidentialInformation" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.confidentialInformation === "Yes"}/>
							<CustomInput type="radio" id="confidentialInformationNo" name="confidentialInformation" label="No" value="No" onChange={
this.handleRadio} checked={data.confidentialInformation === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="moreThanOneState">Are employees located in more than one state?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="moreThanOneStateYes" name="moreThanOneState" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.moreThanOneState === "Yes"}/>
							<CustomInput type="radio" id="moreThanOneStateNo" name="moreThanOneState" label="No" value="No" onChange={
this.handleRadio} checked={data.moreThanOneState === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="nonCompete">Do employees sign non-compete and non-solicit agreements?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="nonCompeteYes" name="nonCompete" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.nonCompete === "Yes"}/>
							<CustomInput type="radio" id="nonCompeteNo" name="nonCompete" label="No" value="No" onChange={
this.handleRadio} checked={data.nonCompete === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="pto">Do you have policies in place regarding sick leave, family leave and/or PTO?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="ptoYes" name="pto" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.pto === "Yes"}/>
							<CustomInput type="radio" id="ptoNo" name="pto" label="No" value="No" onChange={
this.handleRadio} checked={data.pto === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="backgroundCheck">Do you require any type of background check?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="backgroundCheckYes" name="backgroundCheck" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.backgroundCheck === "Yes"}/>
							<CustomInput type="radio" id="backgroundCheckNo" name="backgroundCheck" label="No" value="No" onChange={
this.handleRadio} checked={data.backgroundCheck === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="hourly">Do you employ hourly employees?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="hourlyYes" name="hourly" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.hourly === "Yes"}/>
							<CustomInput type="radio" id="hourlyNo" name="hourly" label="No" value="No" onChange={
this.handleRadio} checked={data.hourly === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="determiningHourly">Do you have a process for determining whether an employee should be hourly or salaried?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="determiningHourlyYes" name="determiningHourly" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.determiningHourly === "Yes"}/>
							<CustomInput type="radio" id="determiningHourlyNo" name="determiningHourly" label="No" value="No" onChange={
this.handleRadio} checked={data.determiningHourly === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="usRights">Do all employees have rights to work in the U.S.?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="usRightsYes" name="usRights" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.usRights === "Yes"}/>
							<CustomInput type="radio" id="usRightsNo" name="usRights" label="No" value="No" onChange={
this.handleRadio} checked={data.usRights === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
				</CardBody>
			</Card>
			}

			<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">Contracts</h4>
				<CardBody>
					<FormGroup>
						<Label for="leasingSpace">Is your company currently leasing any retail/commercial/industrial space?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="leasingSpaceYes" name="leasingSpace" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.leasingSpace === "Yes"}/>
							<CustomInput type="radio" id="leasingSpaceNo" name="leasingSpace" label="No" value="No" onChange={
this.handleRadio} checked={data.leasingSpace === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="leasingEquipment">Does your company lease any equipment FROM or TO another company?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="leasingEquipmentYes" name="leasingEquipment" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.leasingEquipment === "Yes"}/>
							<CustomInput type="radio" id="leasingEquipmentNo" name="leasingEquipment" label="No" value="No" onChange={
this.handleRadio} checked={data.leasingEquipment === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="licensingAgreements">Does your company currently have any licensing agreements in place?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="licensingAgreementsYes" name="licensingAgreements" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.licensingAgreements === "Yes"}/>
							<CustomInput type="radio" id="licensingAgreementsNo" name="licensingAgreements" label="No" value="No" onChange={
this.handleRadio} checked={data.licensingAgreements === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="manufacturingAgreements">Does your company have any manufacturing agreements in place with a manufacturer?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="manufacturingAgreementsYes" name="manufacturingAgreements" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.manufacturingAgreements === "Yes"}/>
							<CustomInput type="radio" id="manufacturingAgreementsNo" name="manufacturingAgreements" label="No" value="No" onChange={
this.handleRadio} checked={data.manufacturingAgreements === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="templateInvoice">Does your company have a template invoice it is using?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="templateInvoiceYes" name="templateInvoice" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.templateInvoice === "Yes"}/>
							<CustomInput type="radio" id="templateInvoiceNo" name="templateInvoice" label="No" value="No" onChange={
this.handleRadio} checked={data.templateInvoice === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="termsAndConditionsTemplate">Does your company have a Terms & Conditions template in use?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="termsAndConditionsTemplateYes" name="termsAndConditionsTemplate" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.termsAndConditionsTemplate === "Yes"}/>
							<CustomInput type="radio" id="termsAndConditionsTemplateNo" name="termsAndConditionsTemplate" label="No" value="No" onChange={
this.handleRadio} checked={data.termsAndConditionsTemplate === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="confidentialityAgreementThirdParties">Does your company have a form confidentiality agreement it uses with third parties?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="confidentialityAgreementThirdPartiesYes" name="confidentialityAgreementThirdParties" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.confidentialityAgreementThirdParties === "Yes"}/>
							<CustomInput type="radio" id="confidentialityAgreementThirdPartiesNo" name="confidentialityAgreementThirdParties" label="No" value="No" onChange={
this.handleRadio} checked={data.confidentialityAgreementThirdParties === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="confidentialityAgreementEmployees">Does your company have a form confidentiality agreement it uses with employees?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="confidentialityAgreementEmployeesYes" name="confidentialityAgreementEmployees" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.confidentialityAgreementEmployees === "Yes"}/>
							<CustomInput type="radio" id="confidentialityAgreementEmployeesNo" name="confidentialityAgreementEmployees" label="No" value="No" onChange={
this.handleRadio} checked={data.confidentialityAgreementEmployees === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
				</CardBody>
			</Card>


			<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">Financing</h4>
				<CardBody>
					<FormGroup>
						<Label for="outsideInvestors">Does your company have outside investors?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="outsideInvestorsYes" name="outsideInvestors" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.outsideInvestors === "Yes"}/>
							<CustomInput type="radio" id="outsideInvestorsNo" name="outsideInvestors" label="No" value="No" onChange={
this.handleRadio} checked={data.outsideInvestors === "No"}/>
						</div>
						<FormFeedback/>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="givenStock">Has your company given away stock or interests to such investors?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="givenStockYes" name="givenStock" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.givenStock === "Yes"}/>
							<CustomInput type="radio" id="givenStockNo" name="givenStock" label="No" value="No" onChange={
this.handleRadio} checked={data.givenStock === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="outsideDebt">Does your company have any outside debt?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="outsideDebtYes" name="outsideDebt" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.outsideDebt === "Yes"}/>
							<CustomInput type="radio" id="outsideDebtNo" name="outsideDebt" label="No" value="No" onChange={
this.handleRadio} checked={data.outsideDebt === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="additionalFinancing">Has your company considered additional financing?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="additionalFinancingYes" name="additionalFinancing" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.additionalFinancing === "Yes"}/>
							<CustomInput type="radio" id="additionalFinancingNo" name="additionalFinancing" label="No" value="No" onChange={
this.handleRadio} checked={data.additionalFinancing === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
				</CardBody>
			</Card>


			<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">Proprietary Technology</h4>
				<CardBody>
					<FormGroup>
						<Label for="proprietaryTechnology">Do you have proprietary technology that you utilize that you believe distinguishes you in your industry or from your competitors?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="proprietaryTechnologyYes" name="proprietaryTechnology" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.proprietaryTechnology === "Yes"}/>
							<CustomInput type="radio" id="proprietaryTechnologyNo" name="proprietaryTechnology" label="No" value="No" onChange={
this.handleRadio} checked={data.proprietaryTechnology === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
				</CardBody>
			</Card>

			{ data.proprietaryTechnology === "Yes" &&
				<React.Fragment>
					<Card outline color="primary">
						<h4 className="card-header text-white bg-primary">Protectable Technology</h4>
						<CardBody>
							<FormGroup>
								<Label for="protectableTechnology">Have you taken any steps to determine whether you technology is protectable?</Label>
								<div className="switch-field">
									<CustomInput type="radio" id="protectableTechnologyYes" name="protectableTechnology" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.protectableTechnology === "Yes"}/>
									<CustomInput type="radio" id="protectableTechnologyNo" name="protectableTechnology" label="No" value="No" onChange={
this.handleRadio} checked={data.protectableTechnology === "No"}/>
								</div>
								<FormFeedback/>
							</FormGroup>
							<FormGroup>
								<Label for="unknownTechnology">Is any of your technology valuable in part because it is not known by your competitors?</Label>
								<div className="switch-field">
									<CustomInput type="radio" id="unknownTechnologyYes" name="unknownTechnology" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.unknownTechnology === "Yes"}/>
									<CustomInput type="radio" id="unknownTechnologyNo" name="unknownTechnology" label="No" value="No" onChange={
this.handleRadio} checked={data.unknownTechnology === "No"}/>
								</div>
								<FormFeedback/>
							</FormGroup>
						</CardBody>
					</Card>

					{ data.unknownTechnology === "Yes" &&
				<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">Confidential Technology</h4>
				<CardBody>
					<FormGroup>
						<Label for="confidentialTechnology">Have you taken any steps to determine whether you technology is protectable?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="confidentialTechnologyYes" name="confidentialTechnology" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.confidentialTechnology === "Yes"}/>
							<CustomInput type="radio" id="confidentialTechnologyNo" name="confidentialTechnology" label="No" value="No" onChange={
this.handleRadio} checked={data.confidentialTechnology === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
				</CardBody>
			</Card>
			}
				</React.Fragment>
			}


			<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">Name/Logo</h4>
				<CardBody>
					<FormGroup>
						<Label for="logo">Do you offer your goods or services using a particular name or logo?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="logoYes" name="logo" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.logo === "Yes"}/>
							<CustomInput type="radio" id="logoNo" name="logo" label="No" value="No" onChange={
this.handleRadio} checked={data.logo === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
				</CardBody>
			</Card>


			{ data.logo === "Yes" &&
				<Card outline color="primary">
					<h4 className="card-header text-white bg-primary">Trademarks</h4>
					<CardBody>
						<FormGroup>
							<Label for="trademarkInfringement">Have you searched to be sure that the name or logo is free from trademark infringement?</Label>
							<div className="switch-field">
								<CustomInput type="radio" id="trademarkInfringementYes" name="trademarkInfringement" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.trademarkInfringement === "Yes"}/>
								<CustomInput type="radio" id="trademarkInfringementNo" name="trademarkInfringement" label="No" value="No" onChange={
this.handleRadio} checked={data.trademarkInfringement === "No"}/>
							</div>
							<FormFeedback/>
						</FormGroup>
						<FormGroup>
							<Label for="trademarkRegistration">Have you filed for federal trademark registration?</Label>
							<div className="switch-field">
								<CustomInput type="radio" id="trademarkRegistrationYes" name="trademarkRegistration" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.trademarkRegistration === "Yes"}/>
								<CustomInput type="radio" id="trademarkRegistrationNo" name="trademarkRegistration" label="No" value="No" onChange={
this.handleRadio} checked={data.trademarkRegistration === "No"}/>
							</div>
							<FormFeedback/>
						</FormGroup>
					</CardBody>
				</Card>
			}


			<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">Technology by Contractors</h4>
				<CardBody>
					<FormGroup>
						<Label for="thirdPartyContractors">Have you used any third party contractors to build a prototype, design a logo, create software, or provide any other type of technology or innovation?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="thirdPartyContractorsYes" name="thirdPartyContractors" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.thirdPartyContractors === "Yes"}/>
							<CustomInput type="radio" id="thirdPartyContractorsNo" name="thirdPartyContractors" label="No" value="No" onChange={
this.handleRadio} checked={data.thirdPartyContractors === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
				</CardBody>
			</Card>

			{ data.thirdPartyContractors === "Yes" &&
				<Card outline color="primary">
					<h4 className="card-header text-white bg-primary">IP Contracts with Contractors</h4>
					<CardBody>
						<FormGroup>
							<Label for="contractsWithContractors">Have you entered into contracts with those contractors to make sure you own any intellectual property by the contractors for you?</Label>
							<div className="switch-field">
								<CustomInput type="radio" id="contractsWithContractorsYes" name="contractsWithContractors" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.contractsWithContractors === "Yes"}/>
								<CustomInput type="radio" id="contractsWithContractorsNo" name="contractsWithContractors" label="No" value="No" onChange={
this.handleRadio} checked={data.contractsWithContractors === "No"}/>
							</div>
							<FormFeedback/>
						</FormGroup>
					</CardBody>
				</Card>
			}


			<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">Apps &amp; Website</h4>
				<CardBody>
					<FormGroup>
						<Label for="appOrWebsite">Do you have an app or website?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="appOrWebsiteYes" name="appOrWebsite" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.appOrWebsite === "Yes"}/>
							<CustomInput type="radio" id="appOrWebsiteNo" name="appOrWebsite" label="No" value="No" onChange={
this.handleRadio} checked={data.appOrWebsite === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
				</CardBody>
			</Card>

			{data.appOrWebsite === "Yes" &&
				<Card outline color="primary">
					<h4 className="card-header text-white bg-primary">Apps/Website Privacy Policy</h4>
					<CardBody>
						<FormGroup>
							<Label for="privacyPolicy">Do you have a Privacy Policy and Terms of Use for your app or website?</Label>
							<div className="switch-field">
								<CustomInput type="radio" id="privacyPolicyYes" name="privacyPolicy" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.privacyPolicy === "Yes"}/>
								<CustomInput type="radio" id="privacyPolicyNo" name="privacyPolicy" label="No" value="No" onChange={
this.handleRadio} checked={data.privacyPolicy === "No"}/>
							</div>
							<FormFeedback/>
						</FormGroup>
					</CardBody>
				</Card>
			}

			<Card outline color="primary">
				<h4 className="card-header text-white bg-primary">General</h4>
				<CardBody>
					<FormGroup>
						<Label for="name">Name:</Label>
						<Input id="name" type="text" placeholder="Name" value={Format.formatTextInput(data.name)
} onChange={this.handleText} valid={validationState.name}/>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="companyName">Company Name:</Label>
						<Input id="companyName" type="text" placeholder="Company Name" value={
Format.formatTextInput(data.companyName)} onChange={this.handleText} valid={validationState.companyName}/>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="email">E-mail:</Label>
						<Input id="email" type="text" placeholder="E-mail" value={Format.formatTextInput(
							data.email)
} onChange={this.handleText} valid={validationState.email}/>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="phone">Phone:</Label>
						<Input id="phone" type="text" placeholder="Phone" value={Format.formatTextInput(data.phone)
} onChange={this.handleText} valid={validationState.phone}/>
						<FormFeedback/>
					</FormGroup>
					<FormGroup>
						<Label for="chatted">Have you chatted with an HB Attorney prior to this?</Label>
						<div className="switch-field">
							<CustomInput type="radio" id="chattedYes" name="chatted" label="Yes" value="Yes" onChange={
this.handleRadio} checked={data.chatted === "Yes"}/>
							<CustomInput type="radio" id="chattedNo" name="chatted" label="No" value="No" onChange={
this.handleRadio} checked={data.chatted === "No"}/>
						</div>
						<FormFeedback/>
					</FormGroup>
					{ data.chatted === "Yes" &&
					<FormGroup>
						<Label for="hbAttorneyName">Name of HB Attorney:</Label>
						<Input id="hbAttorneyName" type="text" placeholder="Attorney Name" value={Format.formatTextInput(data.hbAttorneyName)
} onChange={this.handleText} valid={validationState.hbAttorneyName}/>
						<FormFeedback/>
					</FormGroup>
					}
				</CardBody>
			</Card>


			<br />
			<Submit show={showSubmit} cancel={this.cancelSubmit} ok={this.okSubmit} />
			<Button color="primary" onClick={this.showSubmit} className="float-right" style={{margin:10}}>Submit</Button>
			</div>
		);
	}
}

export default connect()(Home);