// Utilities

const utils = {
	getValidationStateCheckbox: function(checked, defaultValidationState = null) {
		if (checked === null || checked === undefined) return defaultValidationState;
		return "success";
	},

	getValidationStateCollection: function(collection, min, getValidationFunction = null, defaultValidationState = null) {
		if (collection === null || collection === undefined) return defaultValidationState;
		if (collection.length === 0) return null;
		if (collection.length < min) return "warning";
		if (getValidationFunction) {
			for (let i = 0; i < collection.length; i++) {
				const item = collection[i];
				const itemResult = getValidationFunction(item);
				if (itemResult === "warning" || itemResult === "danger") return "warning";
			}
		}
		return "success";
	},

	getValidationStateComments: function(comments, defaultValidationState = null) {
		if (comments === null || comments === undefined || comments.toString().trim() === "") return defaultValidationState;
		//else if (comments.length < 10) return "warning";
		return "success";
	},

	getValidationStateDm: function(dm, defaultValidationState = null) {
		if (dm === null || dm === undefined || dm.toString().trim() === "") return defaultValidationState;
		else if (!this.isNumeric(dm)) return "error";
		return "success";
	},

	getValidationStateHours: function(hours, defaultValidationState = null) {
		if (hours === null || hours === undefined || hours === "") return defaultValidationState;
		else if (!this.isNumeric(hours)) return "error";
		return "success";
	},

	getValidationStateRevenue: function(revenue, defaultValidationState = null) {
		if (revenue === null || revenue === undefined || revenue === "") return defaultValidationState;
		else if (!this.isNumeric(revenue)) return "error";
		return "success";
	},

	getValidationStateSelect: function(select, defaultValidationState = null) {
		if (select === null || select === undefined || select === "") return defaultValidationState;
		return "success";
	},

	getValidationStateText: function(text, defaultValidationState = null) {
		if (text === null || text === undefined || text.toString().trim() === "") return defaultValidationState;
		return "success";
	},

	hasText: function(text) {
		if (text === null || text === undefined || text.toString().trim() === "") return false;
		return true;
	},

	isDefined: function(value) {
		return (value !== undefined && value !== null);
	},

	isNumeric: function(value) {
		//return !Number.isNaN(parseFloat(value)) && isFinite(value);
		return !isNaN(value - parseFloat(value));
	},

	isTextComplete: function(value, minLength = 1) {
		return value !== undefined && value !== null && value.toString().length >= minLength;
	}
};

export default utils;