import React, { PureComponent } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";

export default class Submit extends PureComponent {
	cancel = (e) => {
		if (e) e.stopPropagation();
		const { cancel } = this.props;
		cancel();
	}

	ok = (e) => {
		if (e) e.stopPropagation();
		const { ok } = this.props;
		ok();
	}

	render() {
		const { show, text, title } = this.props;
		return (
			<Modal isOpen={show} toggle={this.cancel}>
				<ModalHeader toggle={this.cancel}>
					{title}
				</ModalHeader>
				<ModalBody>
					<p>{text}</p>
				</ModalBody>
				<ModalFooter>
					<Button type="submit" color="success" onClick={this.ok} autoFocus>OK</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

Submit.defaultProps = {
	title: "Thank You",
	text: "Thank you for completing the startup questionnaire. Your information has been successfully submitted. A Husch Blackwell startup attorney will reach out with your diagnostic report within the next business day."
};

Submit.propTypes =
{
	cancel: PropTypes.func.isRequired,
	ok: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	text: PropTypes.string,
	title: PropTypes.string
};