import React from "react";
import { Col, Container, Row } from "reactstrap";

export default props => (
	<div>
		<Container>
			<Row>
				<Col>
					{props.children}
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="disclaimer">&copy; 2023 Husch Blackwell LLP. All rights reserved</div>
				</Col>
			</Row>
		</Container>
	</div>
);